@font-face {
	font-family: "gothammedium";
	src: url("../../fonts/GothamMedium.woff2") format("woff2"),
		url("../../fonts/GothamMedium.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gothambold";
	src: url("../../fonts/GothamBold.woff2") format("woff2"),
		url("../../fonts/GothamBold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gothambook";
	src: url("../../fonts/GothamBook.woff2") format("woff2"),
		url("../../fonts/GothamBook.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gothamlight";
	src: url("../../fonts/GothamLight.woff2") format("woff2"),
		url("../../fonts/GothamLight.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "gothamblack";
	src: url("../../fonts/GothamBlack.woff2") format("woff2"),
		url("../../fonts/GothamBlack.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap");

/* source-sans-pro-regular - latin */
@font-face {
	font-family: "Source Sans Pro Regular";
	font-style: normal;
	font-weight: 400;
	src: url("../../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff2")
			format("woff2"),
		url("../../fonts/source-sans-pro/source-sans-pro-v21-latin-regular.woff")
			format("woff");
}

/* source-sans-pro-700 - latin */
@font-face {
	font-family: "Source Sans Pro Bold";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff2")
			format("woff2"),
		url("../../fonts/source-sans-pro/source-sans-pro-v21-latin-700.woff")
			format("woff");
}
/* noteworthy-bold */
@font-face {
	font-family: "noteworthybold";
	font-style: normal;
	font-weight: 700;
	src: url("../../fonts/noteworthy/Noteworthy-Bold.ttf") format("truetype");
}

/* noteworthy-light */
@font-face {
	font-family: "noteworthylight";
	font-style: normal;
	font-weight: 300;
	src: url("../../fonts/noteworthy/Noteworthy-Light.ttf") format("truetype");
}

.close-dialog-icon {
	color: black;
	width: 2em !important;
	height: 2em !important;
	cursor: pointer;
}

.dialog-title {
	text-align: end;
}

.rankingContainer {
	z-index: 10;
	position: inherit;
}

.title6 {
	font-family: gothamblack;
	color: white;
	font-size: 30px;
	letter-spacing: 1.1px;
}

.num-sellos {
	font-family: gothamblack;
	font-size: 30px;
	letter-spacing: 1.1px;
}

.title8 {
	font-family: "Source Sans Pro Bold" !important;
	font-size: 20px;
	letter-spacing: 4px;
}

.teamfoto-small {
	position: absolute;
	border-radius: 50%;
	height: 80px;
	width: 80px;
	left: 59.5px;
	top: 8px;
	z-index: 9999 !important;
}
.teamfoto-small img {
	border-radius: 50%;
	object-fit: cover;
	height: 85px;
	width: 85px;
}

.rankingText {
	background-image: url("../../assets/ranking/contador-estrella.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	padding: 2rem 2rem 2rem 4rem;
}

.rankingText-time {
	letter-spacing: 0.5px;
	z-index: 100;
}

.figure-line {
	background-color: #690ed1;
	width: 1.5px;
	height: 20px;
}

.figure-3rd::after {
	content: "";
	width: 81px;
	height: 20px;
	position: absolute;
	bottom: 140px;
	left: 14px;
	background: linear-gradient(
		0deg,
		rgba(244, 231, 42, 0.703) 0%,
		rgba(36, 40, 79, 1) 100%
	);
	-webkit-transform: skew(35deg);
	-webkit-transform-origin: top;

	-moz-transform: skew(35deg);
	-moz-transform-origin: top;

	-ms-transform: skew(35deg);
	-ms-transform-origin: top;

	-o-transform: skew(35deg);
	-o-transform-origin: top;

	transform: skew(-35deg);
	transform-origin: top;
}

.figure-3rd::before {
	content: "";
	width: 15px;
	height: 141px;
	position: absolute;
	top: -11px;
	right: -15px;
	background: linear-gradient(
		90deg,
		rgba(244, 231, 42, 0.703) 0%,
		rgba(36, 40, 79, 1) 100%
	);
	-webkit-transform: skewY(55deg);
	-webkit-transform-origin: top;

	-moz-transform: skewY(55deg);
	-moz-transform-origin: top;

	-ms-transform: skewY(55deg);
	-ms-transform-origin: top;

	-o-transform: skewY(55deg);
	-o-transform-origin: top;

	transform: skewY(-55deg);
	transform-origin: top;
}

.figure-3rd {
	height: 140px;
	width: 80px;
	position: relative;
	background-color: #f4e72a;
	margin-left: 27%;
}

.figure-3rd div {
	top: 140px;
}

.figure-2nd::after {
	content: "";
	width: 81px;
	height: 20px;
	position: absolute;
	bottom: 190px;
	left: -14px;
	background: linear-gradient(
		0deg,
		rgba(244, 231, 42, 0.703) 0%,
		rgba(36, 40, 79, 1) 100%
	);
	-webkit-transform: skew(35deg);
	-webkit-transform-origin: top;

	-moz-transform: skew(35deg);
	-moz-transform-origin: top;

	-ms-transform: skew(35deg);
	-ms-transform-origin: top;

	-o-transform: skew(35deg);
	-o-transform-origin: top;

	transform: skew(35deg);
	transform-origin: top;
}

.figure-2nd::before {
	content: "";
	width: 15px;
	height: 191px;
	position: absolute;
	top: -12px;
	left: -15px;
	background: linear-gradient(
		270deg,
		rgba(244, 231, 42, 0.703) 0%,
		rgba(36, 40, 79, 1) 100%
	);
	-webkit-transform: skewY(55deg);
	-webkit-transform-origin: top;

	-moz-transform: skewY(55deg);
	-moz-transform-origin: top;

	-ms-transform: skewY(55deg);
	-ms-transform-origin: top;

	-o-transform: skewY(55deg);
	-o-transform-origin: top;

	transform: skewY(55deg);
	transform-origin: top;
}

.figure-2nd {
	height: 190px;
	width: 80px;
	position: relative;
	background-color: #f4e72a;
	margin-left: 27%;
}

.figure-2nd div {
	top: 190px;
}

.figure-1st::after {
	content: "";
	width: 52px;
	left: 14px;
	height: 20px;
	position: absolute;
	bottom: 250px;
	background: linear-gradient(
		0deg,
		rgba(244, 231, 42, 0.703) 0%,
		rgba(36, 40, 79, 1) 100%
	);
	-webkit-transform: perspective(0px) rotateX(2deg) translate3d(0, 0, 50px);
	transform: perspective(0px) rotateX(2deg);
}

.figure-1st {
	height: 245px;
	width: 80px;
	position: relative;
	background-color: #f4e72a;
	z-index: 9;
	margin-left: 27%;
}

.figure-1st div {
	top: 245px;
}

.background-image {
	position: absolute;
	bottom: 0;
}

.ranking-row {
	z-index: 100;
	position: relative;
}

.bigTextB {
	font-size: 18px;
	font-family: gothambook;
	line-height: 25px;
	letter-spacing: 0.56px;
}

.txtWhite {
	color: #fff !important;
}

.separator-ranking {
	width: 100%;
	background-color: rgba(40, 60, 144, 1);
	height: 1px;
}

.bgBlue {
	background-color: #24284f !important;
}

.yellowBottom {
	position: absolute;
	height: 16px;
	background-color: #f4e72a;
	width: 100%;
	bottom: 0;
}

.smallTextB {
	font-size: 12px;
	letter-spacing: 0.08px;
	line-height: 18px;
}

.legalGebro {
	bottom: 25px;
	color: white;
}

.bg-confeti-ranking {
	background-image: url("../../assets/ranking/confeti.png");
	background-repeat: no-repeat;
	background-position: left 70% center;
	background-size: 120%;
	height: 100%;
}

.txt-blue {
	color: #1b3a94 !important;
}
.txt-brown {
	color: rgba(102, 102, 102, 1) !important;
}
.txt18 {
	font-size: 18px !important;
}
.txt28 {
	font-size: 28px !important;
}
